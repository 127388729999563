// obtain plugin
var cc = initCookieConsent();

// run plugin with your configuration
cc.run({
  current_lang: 'fr',
  autoclear_cookies: true,                   // default: false
  page_scripts: true,                        // default: false

  // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  // delay: 0,                               // default: 0
  // auto_language: null                     // default: null; could also be 'browser' or 'document'
  // autorun: true,                          // default: true
  // force_consent: false,                   // default: false
  // hide_from_bots: false,                  // default: false
  // remove_cookie_tables: false             // default: false
  // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
  // cookie_expiration: 182,                 // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  // cookie_domain: location.hostname,       // default: current domain
  // cookie_path: '/',                       // default: root
  // cookie_same_site: 'Lax',                // default: 'Lax'
  // use_rfc_cookie: false,                  // default: false
  // revision: 0,                            // default: 0

  onFirstAction: function(user_preferences, cookie){
      // callback triggered only once
  },

  onAccept: function (cookie) {
      // ...
  },

  onChange: function (cookie, changed_preferences) {
      // ...
  },

  languages: {
    'fr': {
      consent_modal: {
        title: 'Les cookies ? Chez nous, on les mange...',
        description: 'Chez La Croquante, nous n\'utilisons que les cookies nécessaires au bon fonctionnement du site. <button type="button" data-cc="c-settings" class="cc-link">Plus de détails</button>',
        primary_btn: {
          text: "J'ai compris",
          role: 'accept_all'              // 'accept_selected' or 'accept_all'
        },
      },
      settings_modal: {
        title: 'Détail des cookies',
        save_settings_btn: 'Fermer',
        accept_all_btn: "J'ai compris",
        cookie_table_headers: [
          {col1: 'Nom'},
          {col3: 'Durée max.'},
          {col4: 'Description'}
        ],
        blocks: [
          {
            title: 'Nos cookies 📢',
            description: 'Nous utilisons des cookie pour assurer les fonctionalités de base du site et améliorer votre expérience.'
          },
          {
            title: 'Les cookies nécessaires',
            description: 'Voici la liste des cookies indispensables au bon fonctionnement du site',
            toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
            },
            cookie_table: [             // list of all expected cookies
              {
                col1: 'sessionid',
                col3: '11 jours',
                col4: 'Assure une expérience utilisateur optimale',
              },
              {
                col1: '__stripe-mid',
                col3: '24 heures',
                col4: 'Sécurise les paiements',
              },
              {
                col1: '__stripe-sid',
                col3: '30 minutes',
                col4: 'Sécurise les paiements',
              },
            ]
          },
        ]
      }
    }
  }
});
